import { QuestionTypeInfo } from '../../components/QuestionTypes/QuestionTypeInfo' // absolute import is important for Jest to work

export const MINIMUM_INPUT_WIDTH_PERCENT = 15
export const FOCUS_ANIMATION_DURATION_IN_MS = 1000

export const SCALE_1 = 0
export const SCALE_2 = 1

export const themeOptions = [
  // {
  //   label: 'BootsWatch',
  //   value: 'bootswatch',
  // },
  // {
  //   label: 'Fruity',
  //   value: 'fruity',
  // },
  {
    label: 'Fruity_twentythree',
    value: 'fruity_twentythree',
  },
  // {
  //   label: 'Vanilla',
  //   value: 'vanilla',
  // },
]
export const formatOptions = [
  {
    label: 'All in one',
    value: 'A',
  },
  {
    label: 'Question by Question',
    value: 'S',
  },
  {
    label: 'Group by Group',
    value: 'G',
  },
]

export const AnswerOrderOptions = {
  NORMAL: 'normal',
  RANDOM: 'random',
  ALPHABETICAL: 'alphabetical',
}

export const NEW_OBJECT_ID_PREFIX = 'temp__'

export const VISUALIZATION_OPTIONS = [
  {
    label: 'List',
    value: 'list',
  },
  {
    label: 'Block',
    value: 'blocks',
  },
  {
    label: 'Buttons',
    value: 'buttons',
  },
]
export const USE_SLIDER_LAYOUT_OPTIONS = [
  {
    label: 'Off',
    value: '0',
  },
  {
    label: 'Stars',
    value: '1',
  },
  {
    label: 'Slider with emoticon',
    value: '2',
  },
]

export const ORDER_OPTIONS = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Random',
    value: 'random',
  },
  {
    label: 'Alphabetical',
    value: 'alphabetical',
  },
]

export const DISPLAY_BUTTON_TYPE_OPTIONS = {
  BUTTONS: { name: 'Buttons', value: '0' },
  RADIO: { name: 'Radio', value: '1' },
}

export const COLUMN_WIDTH_OPTIONS = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: '8%',
    value: 1,
  },
  {
    label: '17%',
    value: 2,
  },
  {
    label: '25%',
    value: 3,
  },
  {
    label: '33%',
    value: 4,
  },
  {
    label: '41%',
    value: 5,
  },
  {
    label: '50%',
    value: 6,
  },
  {
    label: '58%',
    value: 7,
  },
  {
    label: '67%',
    value: 8,
  },
  {
    label: '75%',
    value: 9,
  },
  {
    label: '83%',
    value: 10,
  },
  {
    label: '92%',
    value: 11,
  },
  {
    label: '100%',
    value: 12,
  },
]

export const LABEL_WRAPPER_WIDTH_OPTIONS = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Hidden',
    value: 'hidden',
  },
  {
    label: '8%',
    value: 1,
  },
  {
    label: '17%',
    value: 2,
  },
  {
    label: '25%',
    value: 3,
  },
  {
    label: '33%',
    value: 4,
  },
  {
    label: '41%',
    value: 5,
  },
  {
    label: '50%',
    value: 6,
  },
  {
    label: '58%',
    value: 7,
  },
  {
    label: '67%',
    value: 8,
  },
  {
    label: '75%',
    value: 9,
  },
  {
    label: '83%',
    value: 10,
  },
  {
    label: '92%',
    value: 11,
  },
  {
    label: '100%',
    value: 12,
  },
]

export const COMMENTED_CHECKBOX_OPTIONS = {
  CHECKED: {
    label: 'Checkbox is checked',
    value: 'checked',
  },
  ALWAYS: {
    label: 'No control on checkbox',
    value: 'always',
  },
  UNCHECKED: {
    label: 'Checkbox is unchecked',
    value: 'unchecked',
  },
}

export const SETTINGS_TITLES = {
  SIMPLE: 'Simple',
  GENERAL: 'General',
  DISPLAY: 'Display',
  LOGIC: 'Logic',
  OTHER: 'Other',
  INPUT: 'Input',
  STATISTICS: 'Statistics',
  TIMER: 'Timer',
  LOCATION: 'Location',
  SLIDER: 'Slider',
  THEME_OPTIONS: 'Display theme options',
  FILE_META_DATA: 'File metadata',
}

export const STATES = {
  QUESTION_SETTINGS_OPTIONS: 'question_settings_options',
  IS_SURVEY_ACTIVE: 'is_survey_active',
  CODE_TO_QUESTION: 'code_to_question',
  SURVEY_LANGUAGE: 'survey_language',
  SEARCHED_TERM: 'searched_term',
  CLICKED_SURVEY_SETTINGS: 'is_survey_settings_active',
  IS_EDITOR_STRUCTURE_PANEL_OPEN: 'is_editor_structure_panel_open',
  IS_PREVIEW_MODE: 'is_preview_mode_active',
  IS_SURVEY_SETTINGS_OPEN: 'is_question_settings_open',
  IS_ADDING_QUESTION_OR_GROUP: 'is_adding_questions_or_group',
  CLICKED_QUESTION_GROUP_INDEX: 'clicked_question_group_index',
  SURVEY_ID: 'survey_id',
  SAVE_STATUE: 'save_statue',
  FOCUSED_QUESTION: 'focused_question',
  SURVEY_SERVICE: 'survey_service',
  SURVEY: 'survey',
  AUTH: 'auth',
  BUFFER: 'buffer',
  BUFFER_HASH: 'buffer_hash',
  ERRORS: 'errors',
  USER_SETTINGS: 'user_settings',
  IS_PATCH_SURVEY_RUNNING: 'is_patch_survey_running',
  NUMBER_OF_QUESTIONS: 'number_of_questions',
  HAS_SURVEY_UPDATE_PERMISSION: 'has_survey_update_permission',
  IS_SURVEY_SHARE_MENU_OPEN: 'is_survey_share_menu_open',
  SITE_SETTINGS: 'site_settings',
}

export const DISABLED_QUESTION_TYPES = [
  QuestionTypeInfo.EQUATION.theme,
  QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.theme,
  QuestionTypeInfo.SINGLE_CHOICE_LIST_IMAGE_SELECT.theme,
  QuestionTypeInfo.FILE_UPLOAD.theme,
]

export const TOOLTIP_MESSAGES = {
  ACTIVE_DISABLED: 'Deactivate survey to edit',
  NO_PERMISSION: 'You do not have permission',
}

export const URLS = {
  ADMIN: '/admin',
  SURVEY_OVERVIEW: '/surveyAdministration/view/surveyid/',
}
