import React from 'react'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'

import {
  STATES,
  SETTINGS_TITLES,
  createBufferOperation,
  DISABLED_QUESTION_TYPES,
} from 'helpers'
import { useAppState, useBuffer, useFocused, useSurvey } from 'hooks'
import { SideBarHeader } from 'components/SideBar'
import { CloseIcon } from 'components/icons'
import { ToggleButtons } from 'components/UIComponents'

import { Setting } from './Setting'
import { SETTINGS } from './settings'
import { QuestionTypeInfo } from '../QuestionTypes'

export const QuestionSettings = ({ surveyId }) => {
  const [questionSettingsOptions, setQuestionSettingsOptions] = useAppState(
    STATES.QUESTION_SETTINGS_OPTIONS,
    { isAdvanced: false }
  )
  const { survey, update, language } = useSurvey(surveyId)
  const { addToBuffer } = useBuffer()

  const {
    focused = {},
    unFocus,
    setFocused,
    groupIndex,
    questionIndex,
  } = useFocused()

  const handleOnQuestionCodeClick = () => {
    setFocused(focused, groupIndex, questionIndex)
  }

  const handleUpdate = (question) => {
    const updatedQuestionGroups = [...survey.questionGroups]
    updatedQuestionGroups[groupIndex].questions[questionIndex] = question

    update({
      questionGroups: updatedQuestionGroups,
    })

    setFocused(question, groupIndex, questionIndex)
  }

  const updateAttribute = (value, isAttribute = true) => {
    const updatedQuestion = {
      ...survey.questionGroups[groupIndex].questions[questionIndex],
    }

    if (isAttribute) {
      updatedQuestion.attributes = {
        ...updatedQuestion.attributes,
        ...value,
      }

      handleUpdate(updatedQuestion)
      const operation = createBufferOperation(updatedQuestion.qid)
        .questionAttribute()
        .update({
          ...value,
        })

      addToBuffer(operation)
    } else {
      handleUpdate({ ...updatedQuestion, ...value })

      const operation = createBufferOperation(updatedQuestion.qid)
        .question()
        .update({
          ...value,
        })

      addToBuffer(operation)
    }
  }

  if (!focused.qid) {
    return <></>
  }

  const isQuestionDisabled =
    DISABLED_QUESTION_TYPES.includes(focused.questionThemeName) ||
    !Object.values(QuestionTypeInfo)
      .map((q) => q.theme)
      .includes(focused.questionThemeName)

  return (
    <div
      className={classNames('survey-settings')}
      data-testid="question-settings"
    >
      <SideBarHeader className="right-side-bar-header primary">
        <div
          onClick={handleOnQuestionCodeClick}
          className="focused-question-code"
        >
          Question settings
        </div>
        <Button variant="link" style={{ padding: 0 }} onClick={unFocus}>
          <CloseIcon className="text-black fill-current" />
        </Button>
      </SideBarHeader>
      {!isQuestionDisabled && (
        <>
          {process.env.REACT_APP_DEV_MODE && (
            <div className="mb-3 advanced-toggle">
              <ToggleButtons
                toggleOptions={[
                  { name: 'Simple', value: false },
                  { name: 'Advanced', value: true },
                ]}
                value={!!questionSettingsOptions?.isAdvanced}
                onChange={(value) =>
                  setQuestionSettingsOptions({ isAdvanced: value })
                }
                isSecondary
              />
            </div>
          )}

          {SETTINGS[focused.questionThemeName]?.map((setting, index) => {
            if (
              setting.title === SETTINGS_TITLES.LOGIC &&
              !process.env.REACT_APP_DEV_MODE
            ) {
              return (
                <React.Fragment
                  key={`${setting.title}-${index}`}
                ></React.Fragment>
              )
            }

            return (
              <Setting
                key={`${setting.title}-${index}`}
                question={focused}
                isAdvanced={!!questionSettingsOptions?.isAdvanced}
                simpleSettings={SETTINGS_TITLES.SIMPLE === setting.title}
                handleUpdate={updateAttribute}
                title={setting.title}
                attributes={setting.attributes}
                language={language}
              />
            )
          })}
        </>
      )}
      {isQuestionDisabled && (
        <>
          <p style={{ padding: '5px' }}>
            This Question Type can be only configured in the
            <a
              href={
                `${process.env.REACT_APP_SITE_URL}/questionAdministration/view?surveyid=` +
                focused.sid +
                `&gid=` +
                focused.gid +
                `&qid=` +
                focused.qid
              }
              style={{ color: 'blue' }}
              target="_blank"
              rel="noreferrer"
            >
              core application
            </a>
            .
          </p>
        </>
      )}
    </div>
  )
}
