import React, { useEffect, useState } from 'react'
import { SurveySettingComponent } from '../SurveySettingComponent'
import { useSurvey } from '../../../hooks'
import { cloneDeep } from 'lodash'

export const GoogleAnalytics = (props) => {
  const [selectedSetting, setSelectedSetting] = useState(false)
  const { survey } = useSurvey(props.surveyId)

  const updateSetting = (value, keyPath) => {
    const setting = cloneDeep(props.setting)
    setting.keyPath = keyPath
    setting.ignore = false
    props.update(value, setting)
  }

  useEffect(() => {
    let apiKey = survey.googleAnalyticsApiKey

    if (apiKey === '') {
      setSelectedSetting('N')
    } else if (apiKey === '9999useGlobal9999') {
      setSelectedSetting('G')
    } else {
      setSelectedSetting('Y')
    }
  }, [])

  useEffect(() => {
    if (!selectedSetting) {
      return
    }

    let apiKey = survey.googleAnalyticsApiKey

    if (selectedSetting === 'G') {
      apiKey = '9999useGlobal9999'
    } else if (selectedSetting === 'Y') {
      apiKey = apiKey === '9999useGlobal9999' ? '' : apiKey
    } else {
      apiKey = ''
    }

    updateSetting(apiKey, 'googleAnalyticsApiKey')
  }, [selectedSetting])

  return (
    <>
      <SurveySettingComponent
        onChange={(value) => setSelectedSetting(value)}
        {...props}
        {...props.apiSetting}
        value={selectedSetting}
        update={(value) => updateSetting(value, 'googleAnalyticsApiKeySetting')}
      />
      {selectedSetting === 'Y' && (
        <SurveySettingComponent
          {...props}
          {...props.apiKey}
          keyPath={props.apiKey.keyPath}
          value={survey.googleAnalyticsApiKey}
          update={(value) => updateSetting(value, 'googleAnalyticsApiKey')}
        />
      )}
      {(selectedSetting === 'Y' || selectedSetting === 'G') && (
        <SurveySettingComponent
          {...props}
          {...props.apiStyle}
          keyPath={props.apiStyle.keyPath}
          value={survey.googleAnalyticsStyle}
          update={(value) => updateSetting(value, 'googleAnalyticsStyle')}
        />
      )}
    </>
  )
}
