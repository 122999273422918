import React, { useState } from 'react'

import { SurveySettingComponent } from '../SurveySettingComponent'

export const AdminNotificationsEmail = (props) => {
  const [yesNoToggle, setYesNoToggle] = useState(props.value !== '')
  const updateTurnedOffSetting = () => {
    props.update('')
  }

  return (
    <>
      <SurveySettingComponent
        {...props}
        onChange={(value) => setYesNoToggle(value)}
        update={(value) => updateTurnedOffSetting(value, props.id)}
        mainText={props.mainText}
        value={yesNoToggle}
      />
      {yesNoToggle && (
        <SurveySettingComponent
          {...props}
          {...props.inputProps}
          update={(value) => props.update(value)}
          value={props.value}
        />
      )}
    </>
  )
}
