import classNames from 'classnames'
import Button from 'react-bootstrap/Button'

import { useAppState } from 'hooks'
import { SideBarHeader } from 'components/SideBar'
import { CloseIcon } from 'components/icons'

import { Form } from 'react-bootstrap'
import { STATES } from 'helpers'

const surveySettingsOptions = [
  {
    label: 'General',
    value: 'generalsettings',
  },
  {
    label: 'Presentation',
    value: 'presentation',
  },
  {
    label: 'Privacy Policy',
    value: 'datasecurity',
  },
  {
    label: 'Participants',
    value: 'token',
  },
  {
    label: 'Notifications and data',
    value: 'notification',
  },
  {
    label: 'Publication & access',
    value: 'publication',
  },
  {
    label: 'Advanced options',
    value: 'advancedOptions',
  },
]

export const SurveySettingsComponent = ({ accessibleSettingItems = {} }) => {
  const [clickedSurveySettings, setClickedQuestionSettings] = useAppState(
    STATES.CLICKED_SURVEY_SETTINGS,
    {
      label: 'General',
      value: 'general',
    }
  )
  const [, setEditorStructurePanelOpen] = useAppState(
    STATES.IS_EDITOR_STRUCTURE_PANEL_OPEN,
    true
  )

  const handleClose = () => setEditorStructurePanelOpen(false)

  return (
    <div
      data-testid="survey-settings-panel"
      className="d-flex"
      style={{ height: '100%' }}
    >
      <div
        className="survey-structure px-2"
        style={{ overflowY: 'auto', width: '290px' }}
      >
        <div className={classNames('survey-settings')}>
          <SideBarHeader className="right-side-bar-header primary">
            Survey Settings
            <Button
              variant="link"
              className="p-0 btn-close-lime"
              onClick={handleClose}
              data-testid="btn-close-survey-settings-panel"
            >
              <CloseIcon className="text-black fill-current" />
            </Button>
          </SideBarHeader>
          {surveySettingsOptions.map((option, idx) => {
            // check for access (if the settings menu item is listed in accessibleSettingItems array,
            const visible =
              option?.value === 'generalsettings' || // default "general" item is always visible
              option?.value === 'advancedOptions' || // "advancedOptions" is unknown to the backend
              option?.value in accessibleSettingItems
            return (
              visible && (
                <div
                  key={`${idx}-${option.value}`}
                  onClick={() => setClickedQuestionSettings({ ...option })}
                  className={`px-4 py-3 d-flex align-items-center cursor-pointer rounded ${
                    clickedSurveySettings?.value === option?.value
                      ? 'bg-primary text-white'
                      : 'text-black'
                  }`}
                >
                  <Form.Label
                    className={` cursor-pointer ${
                      clickedSurveySettings?.value === option?.value
                        ? 'text-white'
                        : 'text-black'
                    } mb-0`}
                  >
                    {option.label}
                  </Form.Label>
                </div>
              )
            )
          })}
        </div>
      </div>
    </div>
  )
}
